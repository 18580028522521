.intro {
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: black;

    font-family: 'Arial Bold';
    color: white;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 1.2em;

    transition: opacity 1s;
    margin: 0;
}

.intro-video {
    position: absolute;
    width: 100%;
    height: 100%;
}

.intro-click {
    z-index: 12000;
    position: absolute;
    width: 80%;
    height: 30%;
}

.language-langSelectionContainer {
    display: block;
    width: clamp(200px, 50%, 900px);
}

.language-selectArrow {
    position: relative;
    z-index: 50000;
    width: 1.8em;
    height: 1.8em;
    content: "";

    right: 0%;

    background: url("../../style/assets/landingPage/selectArrow.png") 96% / 15% no-repeat;
    background-size: contain;
    background-position: center;

    filter: invert(100%);
}


.language-langSelection {
    z-index: 80000;
    height: 20px;
    width: 24%;
    top: 6.5%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.language-langSelection__control {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;

    border: 0px solid rgba(255, 255, 255, 0) !important;
    box-sizing: border-box !important;
    box-shadow: none !important;

    background-color: rgba(0, 0, 0, 0) !important;

    text-align: left !important;

    color: white !important;

    cursor: pointer !important;
}

.language-langSelection__indicator-separator {
    display: none !important;
}

.language-langSelection__menu {
    background-color: rgba(255, 255, 255, 0) !important;
    border: 0px solid rgba(255, 255, 255, 0) !important;
    box-shadow: none !important;
}

.language-langSelection__value-container {
    padding: 0 !important;
}

.language-langSelection__indicators {
    width: 12px !important;
    padding: 0px !important;
}

.language-langSelection__indicator {
    width: 12px !important;
    padding: 0px !important;
}

.language-langSelection__option {
    background-color: rgba(0, 0, 0, 0) !important;
    color: black !important;
}

.language-langOption {
    display: flex;
    flex-direction: row;

    font-size: clamp(0.8em, 2vw, 0.8em);

    color: black;

    justify-content: flex-start;
    align-items: center;
}

.language-langFlag {
    min-width: 1.5em;
    height: clamp(1em, 60%, 5em);
    aspect-ratio: 1/1;

    margin-right: 5%;
}

.language-langLang {
    margin: 0 0 0 2%;

    font-family: 'Arial Bold' !important;
    font-size: clamp(0.6em, 2vw, 2em);
    letter-spacing: 0.02rem;

    color: white;
}