@font-face {
  font-family: 'Museo 300';
  font-style: normal;
  font-weight: normal;
  src: local('Museo 300'), url('./style/fonts/Museo300-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Museo 700';
  font-style: normal;
  font-weight: normal;
  src: local('Museo 700'), url('./style/fonts/Museo700-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: normal;
  src: local('OSwald'), url('./style/fonts/Oswald-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'OswaldBold';
  font-style: normal;
  font-weight: normal;
  src: local('OswaldBold'), url('./style/fonts/Oswald-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OswaldLight';
  font-style: normal;
  font-weight: normal;
  src: local('OswaldLight'), url('./style/fonts/Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Arial Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Arial Bold'), url('./style/fonts/ArialBold.ttf') format('truetype');
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home {
  position: absolute;
  z-index: 5000;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 0;
}

.videoInterface{
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
}

.progressbar {
  position: absolute;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 0;

  pointer-events: all;
}

@media screen /*and (orientation: portrait)*/ and (min-aspect-ratio: 2/3) {
  .home {
    height: 100%;
    aspect-ratio: 2/3;
    left: 50%;
    transform: translateX(-50%);
  }

  .progressbar {
    height: 10%;
    bottom: 0%;
    aspect-ratio: 20/3;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen /*and (orientation: portrait)*/ and (min-aspect-ratio: 3/7) and (max-aspect-ratio: 2/3) {
  .home {
    height: 100%;
    width: 100%;
  }

  .progressbar {
    bottom: 0%;
    height: 10%;
    width: 100%;
  }
}
 
@media screen /*and (orientation: portrait) */and (max-aspect-ratio: 3/7) {
  .home {
    aspect-ratio: 3/7;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  .progressbar {
    aspect-ratio: 30/7;
    width: 100%;
    bottom: 0%;
  }
}

/*@media screen and (orientation: landscape) and (max-aspect-ratio: 6/5) {
  .home {
    aspect-ratio: 6/5;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 6/5) and (max-aspect-ratio: 7/3) {

  .home {
    height: 100%;
    width: 100%;
  }
} */

@media screen and (orientation: landscape) and (min-aspect-ratio: 7/3) {

  .home {
    aspect-ratio: 7/3;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.clickable {
  cursor: pointer;
}

.boldtext { font: bold 1em Arial, Helvetica, sans-serif; }